body {
  background-color: $primary-background-color;
  color: $text-color;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

@include medium {
  .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// TODO: ::selection styles are being pulled from bui-theme, but
// the variables are not loaded properly it seems
// this whole bui-theme needs a cleanup
// https://github.com/boosterfuels/fleet-customer/blob/aa5b19c7413206000c830893b382f73f7b8cc267/libs/booster-ui/src/lib/scss/_bui-theme.scss#L18
::selection {
  background: #6f6fff;
  color: #fff;
  text-shadow: none;
}
