form {
  textarea {
    border-radius: 2px;
    border: 1px solid transparent;

    &:focus {
      outline: none;
    }
  }

  select {
    min-height: 23px;
    min-width: 100px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border: 0 !important;
    outline: 1px solid #d8d8d8 !important;
    outline-offset: -1px !important;

    &.ng-invalid {
      border: 1px solid $danger-color !important;
      outline: 0 !important;
    }

    &:focus {
      outline-color: $booster-purple !important;
      box-shadow: none;
    }
  }

  input[type='time']:read-only::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  input[type='number']:read-only::-webkit-inner-spin-button,
  input[type='number']:read-only::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input,
  textarea {
    &[type='datetime-local'][readonly] {
      padding: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }

    &.ng-invalid {
      border: 1px solid $danger-color;
    }

    &:focus {
      border-color: $booster-purple;
      box-shadow: none;
    }
  }

  .single-input-row {
    select {
      outline: 0 !important;
      height: 21px !important;
    }

    &.active {
      input,
      textarea {
        border: 1px solid #d8d8d8;

        &.ng-invalid {
          border: 1px solid $danger-color;
        }

        &:focus {
          border-color: $booster-purple;
          box-shadow: none;
        }
      }

      select {
        outline: 1px solid #d8d8d8 !important;

        &.ng-invalid {
          outline: 1px solid $danger-color !important;
        }

        &:focus {
          outline-color: $booster-purple !important;
          box-shadow: none;
        }
      }
    }

    .btn-group.select {
      .btn-group + .btn-group {
        border: 1px solid #d8d8d8;
        border-left: 0 !important;
      }

      .btn {
        background: #d8d8d8;
        position: relative;
        border-color: #d8d8d8;
        border-left: 0;
        color: black;
        padding: 0;
        width: 22px;
        height: 21px;
        font-size: 0;

        i {
          font-size: 12px;
        }
      }

      & + .btn-group {
        margin-left: 0;
      }
    }
  }
}

.single-input-row fleet-account-async-typeahead .form-control {
  text-align: right !important;
}
