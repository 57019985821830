.fleet-vehicle-create-modal-backdrop {
  z-index: 1500 !important;
}

.fleet-vehicle-create-modal {
  z-index: 1600 !important;

  .modal-body {
    max-width: 90vw;
    max-height: 80vh !important;
    overflow: auto;
    padding: 0 !important;
  }

  .required {
    color: black;
  }

  .sticky-header thead,
  .sticky-header thead th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 250;
    border-bottom: 0px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}
