.custom-number-input,
.custom-zipcode-input {
  &.ng-invalid {
    input {
      border: 1px solid $danger-color;
    }
  }

  & input {
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &--smaller-height {
    input {
      height: 23px;
    }
  }
}

//Price range input

.price-range-slider-container {
  z-index: 200;
  position: absolute;
  left: 15px;
  right: 15px;
  background-color: #fff;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.4);
  padding: 8px 6px;
  display: none;
  transition: height 0.3s ease-in-out;
}

price-range-input:hover .price-range-slider-container {
  display: block;
}

ng5-slider {
  left: 0;
  right: 15px;

  .ng5-slider-pointer {
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
    top: -10px !important;
    background-color: #573672 !important;
    outline: none !important;
    border: 0px !important;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 8px !important;
      left: 8px !important;
      border-radius: 4px;
      background: #fff !important;
    }
  }

  .ng5-slider-selection {
    background-color: rgba(87, 54, 114, 0.7) !important;
  }

  .ng5-slider-bubble {
    font-size: 14px !important;
  }
}

.max-price-label {
  font-size: 14px !important;
}
