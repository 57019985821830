.single-input-row {
  padding: 8px 10px;
  font-size: 13px;
  border-bottom: 1px solid $primary-border-color;

  &:last-child {
    border-bottom: none;
  }

  &.active {
    background-color: $light-bg-color;
  }

  &__label {
    color: $light-text-color;
    margin: 0;
    line-height: 22px;
  }

  select.ng-invalid:not([disabled]) {
    border: 1px solid $danger-color;
  }

  &__input {
    text-align: right;
    outline: none;
    border: none;
    padding: 2px 5px;
    border-radius: 2px;
    border: 1px transparent solid;
    background-color: #fff;
    height: 22px;

    &:disabled {
      &.ng-invalid {
        border: none;
      }
    }
  }

  .ngb-timepicker.ng-invalid input {
    border: 1px solid $danger-color;
  }

  .custom-number-input-line-item {
    input {
      display: inline;
      &:read-only {
        pointer-events: none;
      }
    }
    &--right input {
      text-align: right;
    }
  }

  .custom-number-input,
  .custom-zipcode-input {
    input {
      max-width: 230px;
      text-align: right;
    }

    &--small input {
      max-width: 60px;
    }

    &--small-left input {
      max-width: 60px;
      float: left;
    }

    &--dw-price input {
      text-align: left;
    }

    &--medium input {
      max-width: 80px;
    }
  }

  .form-row-typeahead-cont {
    position: relative;
    float: right;

    input-loader {
      position: absolute;
      top: 0;
      left: 2px;
    }

    .required {
      border: 1px solid $danger-color;
      border-radius: 2px;
    }

    .form-row-typeahead {
      text-align: right;
    }
  }
}
