.kasparov-icon {
  .link {
    &:hover {
      .svg-icon {
        svg {
          fill: #484e52;
        }
      }
    }
  }
  .svg-icon {
    svg {
      transition: 0.35s;
      width: 100%;
      fill: #573672;
    }
  }
}
