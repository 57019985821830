.datepicker-input-container {
  position: relative;
  float: right;
  margin-right: 1px;

  & > .dropdown-menu {
    border-color: #d8d8d8;
  }

  .custom-select {
    font-size: 12px;
    border-color: #ccc;
  }

  ngb-datepicker-navigation-select {
    width: 150px !important;
    select {
      min-width: auto;
    }
  }

  .d-flex {
    justify-content: space-around;
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-week {
    justify-content: space-evenly;
  }

  .ngb-dp-weekday,
  .ngb-dp-day {
    font-size: 10px;
  }

  .ngb-dp-today > div {
    border: 1px solid $booster-logo-purple;
  }

  .toggleDatepicker {
    display: inline-block;
    position: absolute;
    top: 27px;
    right: 1px;
    padding: 4px 10px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    cursor: pointer;

    &--no-top-offset {
      top: 0;
    }
  }

  &.form-row {
    input {
      max-width: 230px;
      height: 23px;
      text-align: right;
      padding-right: 35px;
    }

    label {
      color: $text-color;
    }

    .toggleDatepicker {
      top: 0;
      right: 0;
      padding: 2px 8px;
    }
  }
}
