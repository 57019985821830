.card {
  border-color: $primary-border-color;
  border-radius: 4px;
  margin-bottom: 20px;

  .btn-small {
    padding-bottom: 2px;
    padding-top: 2px;
  }

  a:not(.nav-link) {
    color: $booster-purple;
  }

  .scrollable-y {
    overflow-y: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 0px;
    }
  }

  .scrollable-x {
    overflow-x: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      height: 8px;
    }
  }

  .scrollable {
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 8px;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &--top {
    margin-bottom: 0;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &--no-radius {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &--bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0;
  }

  &--middle {
    margin-bottom: 0;
    border-bottom: 0;
    border-radius: 0;
  }

  &--only-top-border {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  &--no-border {
    border: 0;
  }

  .card-header {
    background-color: $primary-background-color;
    border-color: $primary-border-color;
    min-height: 50px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &--no-border {
      border: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &__right-tools {
      margin-left: auto;

      .btn-small {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      button:last-child {
        margin-right: 0;
      }
      btn-edit-save-cancel {
        margin-right: 5px;
      }
      btn-edit-save {
        margin-right: 5px;
      }
      btn-edit-save-cancel:last-child {
        margin-right: 0;
      }
      btn-edit-save:last-child {
        margin-right: 0;
      }
    }

    &__left-tools {
      margin-right: auto;

      button:last-child {
        margin-right: 0;
      }
    }

    &__text {
      padding: 0 5px;
      color: $light-text-color;
    }

    &__right-tool-item {
      display: inline-block;
      vertical-align: middle;
      position: relative;
    }

    &__link {
      text-decoration: none;
      color: $light-text-color;
      vertical-align: middle;
      margin-left: 10px;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        color: $booster-purple;

        svg path {
          fill: $booster-purple;
        }
      }

      .fa {
        font-size: 16px;
        margin-left: 2px;
      }
    }

    &__typehead-input {
      height: 32px;
    }

    &__updated-at {
      text-align: right;
      font-size: 11px;

      .label {
        font-size: 10px;
        color: #4a4a4a;
      }
    }

    &__filters {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }

  .card-header-title {
    margin: 0 10px 0 0;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
  }

  .card-block {
    padding: 10px;
    position: relative;

    input {
      padding: 0 4px;
    }

    @include medium {
      min-height: 0;
    }

    &.limit-table-height {
      max-height: 630px;
    }

    &--no-padding {
      padding: 0;
    }

    &--with-dropdown-control {
      @include small {
        min-height: 170px;
      }
    }

    &--with-card-table {
      max-height: 922px;
      overflow: auto;
    }

    &__loader {
      position: relative;
      top: 100%;
      width: 100%;
      min-height: 100px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .info-row {
      padding: 9px 10px;
      font-size: 13px;
      border-bottom: 1px solid $primary-border-color;
      display: flex;
      justify-content: space-between;

      span,
      span > copy-to-clipboard {
        vertical-align: middle;
      }

      &:last-child {
        border-bottom: none;
      }

      &--fat-bottom {
        border-bottom-width: 3px;
      }

      &--fat-top {
        border-top: 2px solid #d8d8d8;
      }

      &--unimportant {
        color: #cccccc;
      }

      &__value {
        &--unimportant {
          color: #cccccc;
        }
      }
    }
  }

  &--wide {
    overflow: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    @include large {
      overflow: visible;
    }

    .card-header,
    .card-block {
      min-width: 768px;
    }
  }

  &--small-margin-bottom {
    margin-bottom: 10px;
  }

  .no-wrap {
    white-space: nowrap;
  }
}

.over-scroll {
  overflow: scroll;
}

.accordion > .card {
  margin-bottom: 0;
}

// card-header__right tools elements are in components/_card-table
