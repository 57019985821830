.card .status-icon-btn {
  padding: 0;
  margin: 0 2px;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }

  &--card-header {
    & > .status-icon {
      font-size: 22px;
    }
  }

  &--with-bg {
    background: $dark-background-color;
    color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: 200ms ease-in-out;
    &:hover {
      background: $booster-purple;
    }
    & > .status-icon {
      font-size: 12px;
    }
  }

  .svg-icon {
    svg {
      fill: #fff;
    }
  }
}

.status-icon {
  font-size: 18px;
  vertical-align: middle;

  &__danger {
    color: $danger-color;
  }

  &__success {
    color: $success-color;
  }

  &__warning {
    color: $warning-color;
  }

  &__default {
    color: $dark-background-color;
  }

  &__clickable {
    &:hover {
      color: $booster-purple;
    }
    &--complete {
      &:hover {
        color: $success-color;
      }
    }
    &--pause {
      &:hover {
        color: $warning-color;
      }
    }

    &--cancel {
      &:hover {
        color: $danger-color;
      }
    }
  }

  &__with-svg {
    // height: 26px;
    display: inline-block;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    svg {
      width: 18px;
      height: 20px;
      vertical-align: middle;

      path {
        fill: $dark-background-color;
      }
    }

    &:hover {
      svg path {
        fill: $booster-purple;
      }
    }
  }

  & + a {
    vertical-align: middle;
    margin-left: 5px;
  }
}
