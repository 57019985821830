tree-root.tree-root-with-lines {
  tree-root {
    font-size: 0 !important;
  }

  .tree-children {
    padding-left: 0 !important;
  }

  .node-drop-slot {
    height: 0 !important;
  }

  tree-node-content {
    vertical-align: middle;

    span {
      font-size: 14px;
      vertical-align: middle;
    }
  }

  .tree-line-content {
    &--icon {
      position: relative;
      padding-left: 32px;

      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--disabled {
      opacity: 0.7;
    }
  }
}
