.select-tool {
  background-color: #fff;
  padding: 4px 10px;
  min-width: 120px;
  border: 1px solid $primary-border-color;
  border-radius: 2px;
  text-align: left;
  font-size: 15px;
  outline: none;

  &__option {
    margin-right: 4px;
    color: $light-text-color;
    font-size: 15px;
  }

  &::after {
    color: $light-text-color;
    float: right;
    margin: 9px 0 0 4px;
  }
}

.wide-dropdown-select {
  .select-tool {
    min-width: 170px;
  }
}
