.add-comment {
  .backdrop,
  .backdrop::before,
  *::after {
    box-sizing: border-box;
  }

  textarea,
  textarea::before,
  *::after {
    box-sizing: border-box;
  }

  .container-textarea {
    height: 150px;
  }

  .backdrop,
  textarea {
    width: 100%;
    height: 100%;
  }

  .highlights,
  textarea {
    padding: 5px;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .container-textarea {
    display: block;
    margin: 0 auto;
    transform: translateZ(0);
    -webkit-text-size-adjust: none;
  }

  .backdrop {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
  }

  textarea {
    display: block;
    position: absolute;
    z-index: 2;
    margin: 0;
    border-radius: 0;
    background-color: transparent;
    overflow: auto;
    resize: none;
    transition: transform 1s;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .string-tag {
    color: transparent;
  }
}
.string-tag {
  padding: 0;
  border-radius: 3px;
  background-color: rgba(173, 216, 230, 0.438);
}
