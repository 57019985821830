.multiselect-dropdown {
  text-wrap: nowrap;
  outline: none;
  .dropdown-btn {
    padding: 1px 1px !important;
    background-color: white;
    border: 1px solid #d8d8d8 !important;
    border-radius: 2px !important;
    border-right: 0 !important;
    padding-right: 24px !important;
    outline: none !important;
    height: 24px !important;

    .selected-item {
      font-size: 11px;
      font-weight: 400;
      padding: 1px 11px !important;
      background-color: #f9f9f9 !important;
      color: #000 !important;
      border: 1px solid #d9d9d9 !important;
      border-radius: 10px !important;
      a {
        color: #000 !important;
        text-transform: uppercase;
      }
    }
    .dropdown-multiselect__caret {
      line-height: 20px !important;
      height: 24px !important;
      width: 24px !important;
      padding: 0 !important;
      background-color: #efefef !important;
      border: 1px solid #d8d8d8 !important;
      transform: none !important;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      &::before {
        border-width: 6px 6px 0 !important;
        border-color: #6c757c transparent !important;
        display: inline-block;
        top: 0 !important;
        transition: 0.35s;
      }
    }

    .dropdown-multiselect {
      &--active {
        .dropdown-multiselect__caret {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .dropdown-list {
    margin-top: 5px !important;
    min-width: min-content;
    .multiselect-item-checkbox {
      div {
        &::before {
          color: #666666 !important;
          border: 2px solid #666666 !important;
        }
      }
      input[type='checkbox']:checked + div {
        &::before {
          animation: 2s ease-in !important;
          -webkit-animation: 2s ease-in !important;
          background: #666666 !important;
        }
      }
    }
  }
}

ng-multiselect-dropdown.fulfillment-status-filter {
  min-width: 200px !important;

  .multiselect-dropdown {
    font-size: 15px !important;

    .dropdown-btn {
      min-height: 32px !important;
      height: auto !important;
      line-height: 22px !important;
      border-right: 0px !important;
      padding: 4px 22px 4px 10px !important;
      border: 1px solid #dbdbdb !important;

      .dropdown-multiselect__caret {
        height: 100% !important;
        background: transparent !important;
        width: 32px !important;
        border: 0px !important;

        &::before {
          border-width: 4px 4px 0 !important;
          color: #666666 !important;
          border-color: #666666 transparent !important;
          display: inline-block;
          top: 2.5px !important;
          transition: 0s;
        }
      }

      span {
        color: #6c757d;
      }

      .selected-item {
        line-height: 20px !important;
        padding: 0px 3px !important;
        border-radius: 2px !important;
        max-width: 130px !important;
        text-transform: capitalize;

        span {
          color: #333 !important;
          padding-right: 3px !important;
        }
      }
    }

    .dropdown-list {
      margin-top: 2px !important;
      padding-top: 0px !important;
      border: 1px solid #d4d4d4 !important;
      font-size: 14px !important;
      text-transform: capitalize;

      .multiselect-item-checkbox {
        padding: 4px 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
