.marker {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 18px solid #ffeb3b;
  position: relative;
  z-index: 10;

  &__icon {
    margin: 6px 0 0 -2px;
  }
}
