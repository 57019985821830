.tooltip {
  z-index: 1500;

  .tooltip-inner {
    max-width: 500px;
    font-size: 14px;
  }
}

.has-tooltip {
  border-bottom: 1px dotted;
  cursor: help;
}
