.util {
  &-overflow-x-auto {
    overflow-x: auto;
  }
}

.m {
  &-l-4 {
    margin-left: 4px;
  }

  &-r-4 {
    margin-right: 4px;
  }
}

.p {
  &-t-30 {
    padding-top: 30px;
  }

  &-b-30 {
    padding-bottom: 30px;
  }
}

td.shrink,
th.shrink {
  width: 0.1%;
  white-space: nowrap;
}

.capitalize-text {
  text-transform: capitalize;
}

.warning-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  span {
    margin-left: 4px;
  }
}

.bg-secondary-color {
  background-color: $secondary-background-color;
}

.remove-number-arrows {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.grid-2-columns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
