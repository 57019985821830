.featured-content {
  margin-bottom: 20px;
  background: #dad8d7;
  color: #757575;
  padding: 12px;
  border-radius: 3px;

  &__title {
    line-height: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;

    .fa {
      font-size: 19px;
      float: left;
    }
  }
  &__text {
    margin-left: 12px;
  }

  &__body-wrapper {
    padding: 0 10px 0 27px;
    list-style: none;
    margin: 0px;
  }

  &__body {
    padding: 4px;
    border-bottom: 1px dotted #b0b0b0;
    position: relative;

    &:hover {
      background: lighten(#dad8d7, 5%);
      border-radius: 3px;
      transition: 300ms ease-in-out;

      .featured-content__delete {
        display: block;
        cursor: pointer;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    a {
      color: inherit;
    }
  }

  &__delete {
    display: none;
    float: right;
    background: none;
    border: none;
    color: #b0b0b0;
    outline: none;
    font-size: 12px;

    &:hover {
      color: #757575;
    }

    &:active {
      color: #222;
    }
  }
}
