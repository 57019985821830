.whiteboard-card {
  display: flex;
  min-height: 140px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  position: relative;
  transition: 0.25s;
  &:hover {
    border: 1px solid #6712d7;
    box-shadow: 0 0 0 1px #6712d7;
    cursor: pointer;
  }
  &.selected {
    border: 1px solid #6712d7;
    box-shadow: 0 0 0 1px #6712d7;
  }
  &-selected {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    border: 1px solid #6712d7;
    background-color: #6712d7;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: white;
  }
  &-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    border-right: 1px solid #dcdcdc;
    padding: 0 24px;
  }
  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 90px;
    &-value {
      font-size: 20px;
      font-weight: 900;
    }
    &-label {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      padding: 5px;
    }
  }
}
