.form-row-directive {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > label,
  & > span > label {
    color: $text-color;
    margin: 0px;
    line-height: 22px;
    vertical-align: middle;

    & + copy-to-clipboard {
      margin-left: 5px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  textarea {
    padding: 2px 4px;
    width: 100%;
    max-width: 300px;
  }

  & input {
    outline: none;
    padding: 2px 0px;
    border-radius: 2px;
    border: 1px transparent solid;
    background-color: #fff;
    height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 300px;

    &[type='number'] {
      min-width: 90px;
    }

    &:disabled,
    &:read-only {
      padding: 2px 0px;
      background-color: transparent;
      border-color: transparent;
    }

    &:read-only {
      color: #222;
    }
  }

  & > input {
    text-align: right;
  }

  & select {
    outline: none;
    border-radius: 2px;
    border: 1px transparent solid;
    background-color: #fff;
    height: 22px;

    &:disabled {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $text-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  & > select {
    text-align: right;

    &:disabled {
      direction: rtl;
    }
  }

  ngb-timepicker {
    td {
      padding: 0px;

      input {
        padding: 0px 3px;
        text-align: center;
        width: 26px;
        background-color: #fff;

        &:disabled,
        &:read-only {
          background-color: transparent;
          cursor: auto;
        }
      }
    }
  }

  tag-input-form {
    input {
      height: 34px !important;
    }
  }

  & > td {
    color: $text-color;
  }

  &.tanker-duplicate {
    background: #000 !important;
  }

  &.driver-duplicate {
    background: #f00 !important;
  }
}
