// referenced in ng-package.json
// imported in _bui-theme.scss
@import '../scss/bui-base';

.cdk-table.bui-table {
  --bui-table-font-size: #{b-rem(13px)};
  --bui-table-line-height: #{b-rem(18px)};
  --bui-table-cell-padding: #{b-rem(15px)} #{$bui-table-cell-padding};

  display: table;
  width: 100%;
  table-layout: auto;

  .cdk-row,
  .cdk-header-row,
  .cdk-footer-row {
    display: table-row;
  }

  .cdk-header-row,
  .cdk-footer-row {
    position: relative;
    background-color: use-bui-var('bui-color-grey-f00');
  }

  .cdk-cell,
  .cdk-header-cell,
  .cdk-footer-cell {
    display: table-cell;
    white-space: nowrap;
    font-size: var(--bui-table-font-size);
    line-height: var(--bui-table-line-height);
    padding: var(--bui-table-cell-padding);
    vertical-align: middle;
  }

  .cdk-header-cell,
  .cdk-footer-cell {
    background-color: use-bui-var('bui-color-grey-f00');
    font-weight: 700;
  }

  .cdk-header-cell {
    --bui-table-cell-padding: #{b-rem(10px)} #{$bui-table-cell-padding};
    border-bottom: 1px solid $bui-table-border-color;
    height: b-rem(58px);
  }

  .cdk-footer-cell {
    border-top: 1px solid $bui-table-border-color;
  }

  .cdk-cell {
    background-color: use-bui-var('bui-white');
  }

  // SMALL TABLE
  &.bui-table--small {
    --bui-table-font-size: #{b-rem(11px)};
    --bui-table-line-height: #{b-rem(16px)};
    --bui-table-cell-padding: #{b-rem(10px)} #{b-rem(8px)};
  }

  // ZEBRA TABLE
  &.bui-table--zebra {
    .cdk-row:nth-child(even),
    .cdk-row:nth-child(even) .cdk-cell {
      background-color: use-bui-var('bui-white');
    }
    .cdk-row:nth-child(odd),
    .cdk-row:nth-child(odd) .cdk-cell {
      background-color: $bui-table-row-bg;
    }
    .cdk-footer-row .cdk-footer-cell {
      background-color: use-bui-var('bui-white');
    }
  }

  // RESPONSIVE TABLE
  &.bui-table--responsive {
    .cdk-footer-row .cdk-footer-cell {
      background-color: use-bui-var('bui-white');
    }

    @include mq-max($bui-responsive-table-breakpoint) {
      display: inline-block;
      width: 100%;

      .cdk-header-row {
        // Sticky border on top of the table
        display: block;
        border-top: 1px solid $bui-table-border-color;
        position: sticky;
        z-index: 3;
        top: 0;

        .cdk-header-cell {
          display: none;
        }
      }

      .cdk-row {
        display: flex;
        flex-direction: column;
        padding: $bui-table-cell-padding;

        .cdk-cell {
          display: flex;
          justify-content: space-between;
          width: auto;
          line-height: b-rem(28px);
          padding: 0;
          white-space: normal;
          text-align: right;
        }
      }
    }
  }

  // buiTableResponsiveLabel (shown on small screens only)
  .bui-table-responsive-label {
    display: none;
    font-weight: 700;
  }
  &.bui-table--responsive .bui-table-responsive-label {
    @include mq-max($bui-responsive-table-breakpoint) {
      display: block;
      text-align: left;
    }
  }

  // TABLE ROW HOVER HIGHLIGHT
  @include mq-min($bui-md) {
    &.bui-table--zebra .cdk-row,
    .cdk-row {
      .cdk-cell {
        transition: background-color 250ms ease-in-out;
      }

      &:hover .cdk-cell {
        background-color: $bui-table-row-bg-hover;
      }
    }
  }

  // UTILITY TEXT ALIGNMENT CLASSES
  .bui-table-text-center {
    text-align: center;
  }
  .bui-table-text-left {
    text-align: left;
  }
  .bui-table-text-right {
    text-align: right;
  }
}
