.alert-cont {
  position: fixed;
  right: 20px;
  top: 77px;
  z-index: 1700;
  max-width: 50%;

  @include small {
    left: 0;
    width: 90%;
    margin: 0 auto;
  }

  .alert {
    padding: 10px 10px 10px 15px;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .close {
    font-size: 22px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
