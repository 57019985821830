@import 'variables';

.ng2-tags-container {
  .ng2-tag-input__text-input.invalid {
    border: 1px solid $danger-color;
  }
}

tag-input {
  &.with-loader {
    .ng2-tag-input__text-input {
      width: 200px;
      padding-right: 40px;
    }
  }

  &.disabled {
    .ng2-tag-input {
      .ng-trigger {
        background-color: #efefef !important;
      }

      tag-input-form {
        display: none !important;
      }
    }

    delete-icon {
      display: none !important;
    }
  }
}

.tanker-status {
  padding-left: 6px;
  border-left-width: 4px;
  border-left-style: solid;
  &--unassigned {
    border-color: $success-color;
  }
  &--assigned {
    border-color: $warning-color;
  }
}
