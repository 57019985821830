.dropdown {
  &:has(.assignment-drag-item:empty) {
    display: none !important;
  }

  .dropdown-menu {
    &.assignment-drag-item {
      &:empty {
        display: none !important;
      }

      .dropdown-item {
        &.danger {
          background-color: $danger-highlight !important;
          color: $danger-color !important;
        }
        &.success {
          background-color: $success-highlight !important;
          color: $success-color !important;
        }
        &.warn {
          background-color: $warning-highlight !important;
          color: $warning-color !important;
        }
      }
    }
    right: 0;
    min-width: inherit;

    &.show {
      display: table;
    }

    & > li {
      display: table-row !important;
    }

    & > li > a,
    .dropdown-item {
      color: $text-color;
      display: block;
      padding: 3px 20px;
      font-weight: normal;
      font-size: 14px;
    }

    & > li > a:hover,
    .dropdown-item.active,
    .dropdown-item:hover {
      color: $text-color;
      background-color: #f5f5f5;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

ng2-dropdown-menu {
  .ng2-dropdown-menu {
    z-index: 10000 !important;
  }
}
