////////////////////////
// MEDIA QUERY MIXINS //
////////////////////////
@mixin mq-min($min) {
  @media (min-width: ($min + px)) {
    @content;
  }
}

@mixin mq-max($max) {
  @media (max-width: (($max - 1) + px)) {
    @content;
  }
}

@mixin mq-min-max($min, $max) {
  @media (min-width: ($min + px)) and (max-width: (($max - 1) + px)) {
    @content;
  }
}

// mq mixins in use:
// @include mq-max(980) {}
// @include mq-min(980) {}
// @include mq-min(1100) {}
// @include mq-min-max($lg, $xxl) {}
