.caret::after {
  color: #666666;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  right: 8px;
  top: 50%;
  margin-top: -2px;
}
