.navigation {
  position: fixed;
  z-index: 1200;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: $booster-purple;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  --sublist-max-height: 1800px;

  @include max-large {
    z-index: 1000;
    width: 100%;
    top: 70px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    // this will place it above leaflet toolbars and table-loaders
    z-index: 1002;

    &.active {
      transform: translateX(0);
    }
  }

  @include large {
    transition: width 0.3s ease-in-out;

    &.narrow {
      width: nth($navigation-widths, 1);
    }

    &.expanded {
      width: nth($navigation-widths, 2) !important;
    }

    &:hover {
      width: nth($navigation-widths, 2);
    }
  }

  &__list {
    flex: 1;
    list-style: none;
    padding-left: 0;
    transition: width 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    li {
      padding: 0;
    }

    &:not(:has(.nav-toggle.active)):has(.nav-link.active) {
      .nav-toggle:has(.nav-link.active) {
        background-color: lighten($booster-purple, 5%);
      }
    }

    .nav-toggle {
      cursor: pointer;
      padding: 0;
      transition: background-color 0.4s ease-in-out;

      &:not(:has(.nav-link.active)):hover {
        background-color: lighten($booster-purple, 6%);

        .nav-toggle__label {
          opacity: 1;
        }
        .nav-toggle__icon {
          opacity: 1;
          scale: 1;
        }
      }

      &:not(.active):has(.nav-link.active) {
        background-color: lighten($booster-purple, 3%);
      }

      &.active {
        background-color: lighten($booster-purple, 5%);

        .nav-toggle__label {
          font-weight: 600;
          opacity: 1;
        }
        .nav-toggle__icon {
          scale: 1;
          opacity: 1;
        }
        .nav-toggle__caret {
          rotate: 180deg;
          opacity: 1;
        }

        .nav-toggle__sublist {
          max-height: var(--sublist-max-height);
        }
      }

      > div {
        padding: 12px 0;
      }

      &__icon {
        min-width: nth($navigation-widths, 1);
        text-align: center;
        max-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
        scale: 0.9;
        transition:
          opacity 0.3s ease-in-out,
          scale 0.2s ease-in-out;

        img {
          height: 100%;
        }
      }

      &__label {
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 0.1ch;
        font-size: 12px;
        display: inline;
        opacity: 0.8;
        transition: opacity 0.3s ease-in-out;
      }

      &__caret {
        opacity: 0.8;
        transition:
          transform 0.3s ease-in-out,
          opacity 0.3s ease-in-out,
          rotate 0.3s ease-in-out;
      }

      &__sublist {
        overflow: hidden;
        max-height: 0;
        list-style: none;
        padding-inline-start: 0;
        background: darken($booster-purple, 6%);
        min-width: 200px;
        transition: max-height 0.3s ease-in-out;

        .nav-link {
          padding-block: 14px;
          font-size: 13px;
          letter-spacing: 0.025ch;
          display: flex;
          align-items: center;
          min-width: nth($navigation-widths, 2);
          opacity: 0.8;
          position: relative;
          transition:
            background-color 250ms ease-in-out,
            opacity 250ms ease-in-out;

          &__label {
            white-space: nowrap;
          }

          &__icon {
            width: nth($navigation-widths, 1);
            text-align: center;
            scale: 0.9;
            transition: scale 0.3s ease-in-out;
            img {
              height: 100%;
            }
          }

          &.active,
          &:not(.active):hover {
            opacity: 1;

            .nav-link__icon {
              scale: 1;
            }
          }

          &::after {
            content: '';
            height: 70%;
            width: 3px;
            opacity: 0;
            background-color: #ffffffdb;
            position: absolute;
            top: 50%;
            left: 0;
            translate: 0 -50%;
            border-top-right-radius: 3rem;
            border-bottom-right-radius: 3rem;
            transition:
              opacity 250ms ease-in-out,
              height 250ms ease-in-out;
          }

          &.active {
            &::after {
              opacity: 1;
            }

            .nav-link__label {
              font-weight: 700;
            }
          }

          &:not(.active):hover {
            background: darken($booster-purple, 4%);
          }
        }
      }
    }

    .nav-link {
      color: #fff;
      line-height: 1;

      &__icon {
        height: 16px;
      }
    }

    .logout-link {
      padding: 16px 16px 16px 0 !important;
      &__icon {
        width: nth($navigation-widths, 1);
        text-align: center;
        img {
          height: 20px;
        }
      }
    }
  }

  &__footer {
    @include max-large {
      display: none;
    }

    padding: 8px 0px 8px 0px;
    font-size: 11px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    overflow: hidden;
    line-height: 12px;

    text-align: center;

    &__version {
      font-weight: 700;
    }

    &__environment {
      opacity: 0.75;
    }
  }
}

.horizontal-nav__wrapper {
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
}

.horizontal-nav {
  margin-left: -10px;
  padding-top: 8px;

  .nav-item {
    padding-bottom: 3px;
    padding-top: 3px;

    .nav-link {
      color: $text-color;
      position: relative;
      border-radius: 3px;

      &:hover {
        background: #dbcde1;
      }

      &.active {
        color: $light-purple;

        &::after {
          position: absolute;
          bottom: -4px;
          left: 10px;
          right: 10px;
          content: '';
          height: 2px;
          background-color: $light-purple;
        }
      }
    }
  }
}
