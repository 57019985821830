.ng-select {
  width: 150px;

  .ng-option {
    &:hover {
      background-color: $secondary-background-color !important;
    }

    &.ng-option-selected,
    &.ng-option-marked {
      background-color: $secondary-background-color !important;
    }
  }

  &.ng-select-multiple {
    .ng-value {
      background-color: $light-bg-color !important;

      .ng-value-icon {
        &.left {
          border: 1px solid $light-bg-color !important;
          transition:
            border 0.3s linear,
            background-color 0.3s linear;

          &:hover {
            border: 1px solid darken($light-bg-color, 10%) !important;
            background-color: darken($light-bg-color, 10%) !important;
          }
        }
      }
    }
  }

  &.ng-select-disabled:not(.ng-select-multiple) {
    .ng-select-container {
      min-height: 20px;
      height: 20px;
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      border: none;
      background-color: transparent;
      box-shadow: none;

      .ng-value-container {
        justify-content: right;
        padding-right: 10px;
      }
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-input {
      display: none;
    }

    .ng-value {
      &:nth-last-child(2) {
        margin-right: 0 !important;
      }
    }
  }

  .ng-select-container {
    border-radius: 0;
  }
}
