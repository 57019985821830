a > .fa-external-link {
  margin-left: 2px;
  vertical-align: middle;
}

.scrollable-y {
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// <a> styles which were removed in Bootstrap 5
a {
  text-decoration: none;
  background-color: transparent;
}
