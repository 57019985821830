.card-table {
  width: 100%;
  font-size: 13px;

  .btn-small {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  a {
    color: $booster-purple;

    &:hover {
      text-decoration: underline;
    }
  }

  tr {
    border-bottom: 1px solid $primary-border-color;
    transition: all 0.3s ease;

    &.no-border-bottom {
      border-bottom: none;
    }

    &.alert {
      background-color: $light-alert;
    }

    &.alert:hover {
      background-color: darken($light-alert, 5%);
    }

    &:last-child {
      border-bottom: 0;
    }
    &.single-input-row {
      display: table-row;
      .without-space {
        .btn,
        .btn-round {
          margin-right: 0 !important;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: $light-bg-color;
      }

      &.panel-form__row-highlight:hover {
        background-color: darken($light-alert, 5%);
      }
    }
  }

  thead {
    border-bottom: 2px solid $primary-border-color;
    vertical-align: bottom;

    & > tr {
      border-bottom: none;
    }

    th.colspan-group__title {
      color: #aaa;
      padding-bottom: 2px;
      font-weight: 400;
    }

    th.colspan-group__subtitle {
      padding-top: 0px;
    }
  }

  td {
    max-height: 38px;
    padding: 9px 10px;
    color: $text-color;
    &.without-space {
      white-space: nowrap;
      width: 1px;
    }
  }

  th {
    padding: 10px 10px;
    font-size: 11px;
    color: $light-text-color;
    text-transform: uppercase;
  }

  &--key-value {
    td:first-child {
      color: $light-text-color;
    }

    td:last-child:not(.card-table__vehicle-info) {
      text-align: right;
    }
  }

  .plate {
    display: inline-block;
    font-size: 11px;
    padding: 2px 4px;
    border: 1px solid $primary-border-color;
    border-radius: 2px;
    font-weight: bold;
    color: lighten($text-color, 16%);
    margin-left: 5px;
    transition: all 200ms ease;

    &:first-child:not(.plate--margined) {
      margin-left: 0;
    }

    &--clickable {
      cursor: pointer;
    }

    &[disabled] {
      color: lighten($text-color, 30%);
    }

    &--margined {
      margin-left: 5px;
    }
  }

  &--smaller-cell-padding tbody td {
    padding: 7px 10px;
  }

  &--small-cell-padd td {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &--customers-smaller-cell-padding tbody td {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &--xsmall-cell-padding tbody td {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &--center-last-cell {
    tbody td:last-child,
    thead th:last-child {
      text-align: center;
    }
  }

  &__vehicle-info {
    border-right: 1px solid $primary-border-color;
    text-align: center;
    font-weight: bold;
    color: lighten($text-color, 20%);
    font-size: 46px;
    max-width: 140px;

    @include small {
      font-size: 26px;
    }

    @include large-only {
      font-size: 36px;
    }
  }

  .marked-data {
    color: $base-red-color;
  }

  .panel-table__sort-icon {
    white-space: nowrap;
    color: $light-text-color;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    span {
      font-size: 11px;
      color: $light-text-color;

      &.active {
        color: $text-color;
      }
    }
  }

  &__typehead-results-messages {
    color: $light-grey;
    font-size: 12px;
    margin: 0;
  }

  &__typehead-wrap {
    position: relative;
  }

  &__row-empty-msg {
    text-align: center;
  }

  &--nowrap {
    white-space: nowrap;
  }
}

.card-header__right-tools {
  & > .right-tools__list {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      display: inline-block;
      padding: 0px;
      margin-right: 5px;
    }
  }

  .dropdown-menu {
    margin-top: 8px;
    box-shadow: 0px 0px 12px 0px rgba(80, 80, 80, 0.5);

    & > .right-tools__list {
      list-style: none;
      padding: 8px 12px;
      margin: 0px;

      & > li {
        white-space: nowrap;
        margin-bottom: 8px;
        &:last-of-type {
          margin-bottom: 0;
        }

        .dropdown-component {
          width: 100%;
        }

        & > a {
          display: block;
          line-height: 38px;
          font-size: 12px;
          margin: 0px;
          text-align: center;
        }
      }
    }
  }

  .btn-header-tools-toggle {
    height: 31px;
    background: #fff;
    border: 1px solid #d8d8d8;
    line-height: 10px;
  }

  & > .right-tools__row {
    display: flex;
    flex-direction: row;
  }
}
