$booster-purple: #573672;
$booster-logo-purple: #6712df;
$light-purple: #8517af;
$dark-purple: #3f2356;
$base-white-color: #ffffff;
$light-grey: #84798c;
$light-grey-highlight: #84798c80;
$light-box-shadow: rgba(0, 0, 0, 0.3);
$text-color: #333333;
$light-text-color: lighten($text-color, 20%);
$primary-background-color: #f9f9f9;
$secondary-background-color: #f1f1f1;
$dark-background-color: #888888;
$primary-border-color: #d8d8d8;
$light-bg-color: #efefef;
$success-color: #449d44;
$success-highlight: #449d4430;
$danger-color: #c9302c;
$danger-highlight: #c9312c30;
$base-red-color: #ff0000;
$progress-color: #286090;
$warning-color: #f0ad4e;
$warning-highlight: #f0ad4e30;
$balanced: #33cd5f;
$warn: #ec407a;
$warn-highlight: #f48fb1;
$light-alert: #fbf6cc;
$z-index-1: 1;
$z-index-2: 10;
$z-index-3: 100;
$z-index-override: 1010;
$z-index-override-1: 1020;
$navigation-widths: 60px 280px;
// ------------------------------------------

$retina: 'only screen and (min-resolution: 2dppx)';
$extra-small: '(max-width: 544px)';
$small: '(max-width: 767px)';
$small-only: '(min-width: 544px) and (max-width: 767px)';
$medium: '(min-width: 768px)';
$medium-only: '(min-width: 768px) and (max-width: 991px)';
$max-large: '(max-width: 991px)';
$large: '(min-width: 992px)';
$large-only: '(min-width: 992px) and (max-width: 1199px)';
$xlarge: '(min-width: 1200px)';
$short: '(max-height: 719px)';

:root {
  --bui-size-4: 4px;

  --bui-border-radius: var(--bui-size-4, 4px);

  --bui-white: #fff;
  --bui-black: #000;
  --bui-color-brand-100: #1e0543;
  --bui-color-brand-300: #330970;
  --bui-color-brand-500: #5406bc;
  --bui-color-brand-600: #6712d7;
  --bui-color-brand-700: #7d34df;
  --bui-color-brand-900: #9246e1;
  --bui-color-brand-a00: #a4aecc;
  --bui-color-brand-c00: #c2a0ef;
  --bui-color-brand-e00: #ede3fa;
  --bui-color-brand-f00: #f8f3ff;
  --bui-color-grey-200: #2b2b2b;
  --bui-color-grey-400: #494949;
  --bui-color-grey-600: #646464;
  --bui-color-grey-700: #727272;
  --bui-color-grey-800: #808080;
  --bui-color-grey-900: #979797;
  --bui-color-grey-b00: #bdbdbd;
  --bui-color-grey-c00: #cdcdcd;
  --bui-color-grey-d00: #dcdcdc;
  --bui-color-grey-e00: #e8e8e8;
  --bui-color-grey-f00: #f1f3f3;
  --bui-color-grey-f50: #fbfbfb;
  --bui-color-red: #d20f0f;
  --bui-color-red-highlight: #ffe1e1;
  --bui-color-red-dark: #900000;
  --bui-color-green: #1b7a36;
  --bui-color-green-highlight: #d9e8d7;
  --bui-color-orange-dark: #a16e00;
  --bui-color-orange: #e9ac29;
  --bui-color-orange-highlight: #faebcc;
  --bui-color-blue: #1268d3;
  --bui-color-blue-highlight: #eaf3ff;

  --bui-input-text-align: left;
  --bui-input-font-size: 14px;
  --bui-input-line-height: 20px;
  --bui-input-font-weight: 400;
  --bui-input-padding-block: 9px;
  --bui-input-padding-inline: 10px;
}

.cdk-overlay-container {
  &:has(.bui-aside-layout) {
    z-index: 10000;
  }
}
