@use './bui-base' as base;

// @angular/cdk prebuilt css
@import '@angular/cdk/overlay-prebuilt.css';

html {
  font-family: base.use-bui-var('bui-font-family');
  color: base.use-bui-var('bui-color-grey-200');
  accent-color: base.use-bui-var('bui-color-brand-600');
}

::-moz-selection {
  background: base.use-bui-var('bui-color-brand-900');
  color: base.use-bui-var('bui-white');
  text-shadow: none;
}
::selection {
  background: base.use-bui-var('bui-color-brand-900');
  color: base.use-bui-var('bui-white');
  text-shadow: none;
}

@import '../bui-toast/bui-toast.scss';
@import '../bui-table/bui-table';
