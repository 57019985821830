@mixin retina {
  @media #{$retina} {
    @content;
  }
}

@mixin extra-small {
  @media #{$extra-small} {
    @content;
  }
}

@mixin small {
  @media #{$small} {
    @content;
  }
}

@mixin small-only {
  @media #{$small-only} {
    @content;
  }
}

@mixin medium {
  @media #{$medium} {
    @content;
  }
}

@mixin medium-only {
  @media #{$medium-only} {
    @content;
  }
}

@mixin max-large {
  @media #{$max-large} {
    @content;
  }
}

@mixin large {
  @media #{$large} {
    @content;
  }
}

@mixin large-only {
  @media #{$large-only} {
    @content;
  }
}

@mixin xlarge {
  @media #{$xlarge} {
    @content;
  }
}

@mixin short {
  @media #{$short} {
    @content;
  }
}
