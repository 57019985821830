.popover {
  // one higher than the side navigation z-index
  // important for small screens
  z-index: 1201;

  &.popover-max-width {
    max-width: 100%;
  }

  .popover-header {
    font-size: 13px;
    padding: 5px 10px;
  }

  .popover-body {
    padding: 5px 10px;
    font-size: 12px;
    overflow-x: scroll;

    p {
      margin-bottom: 2px;
    }

    &.alert-row {
      color: red;
    }
  }
}

.whiteboard-popover {
  &.popover {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 10px $light-box-shadow;
    border: none;
    max-width: 100%;

    .popover-body {
      padding: 40px;
      overflow: auto !important;
    }
  }

  &-card {
    &-header {
      border-bottom: 2px solid #dcdcdc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &-title {
        font-size: 20px;
        font-weight: 900;
      }

      &-text {
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-body {
      margin-bottom: 16px;

      table {
        th,
        td {
          font-size: 14px;
          font-weight: 700;
          text-align: right;
          width: 1px;
          padding: 8px 8px;
          white-space: nowrap;
          border-bottom: 1px solid #dcdcdc;

          &:first-child {
            width: auto;
            padding: 8px 0;
            text-align: left;
          }

          &:last-child {
            padding: 8px 0;
          }
        }

        tr {
          &.incomplete {
            color: #dcdcdc;
            transition: 0.25s;
          }

          &.in-progress,
          &.incomplete:hover {
            color: #6712d7;
            td {
              &:first-child {
                position: relative;
                &:before {
                  left: -20px;
                  position: absolute;
                  color: #6712d7;
                  font-family: FontAwesome;
                  content: '\f054';
                }
              }
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;

      .btn {
        font-size: 12px;
        font-weight: 700;
        border: 2px solid #dcdcdc;
        border-radius: 4px;
      }
    }
  }
}

.warning-popover {
  &.popover {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    min-width: 140px !important;
  }

  @each $orientation in top, right, bottom, left {
    &.bs-popover-#{$orientation} .arrow {
      &:before {
        border-#{$orientation}-color: #f5c6cb !important;
      }

      &:after {
        border-#{$orientation}-color: #f8d7da !important;
      }
    }
  }

  .popover-body {
    color: #721c24;
    font-weight: 700;
  }

  .popover-header {
    background-color: #f8d7da;
    color: #721c24;
    border-bottom-color: #f5c6cb;
  }
}
