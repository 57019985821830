.typeahead {
  position: relative;

  &__loader {
    position: absolute;
    left: 2px;
    top: 2px;
    transform: scale(0.9);
  }
}
