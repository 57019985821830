// Overrides Bootstrap variables with rem values.
// A portion of BOA UI was built with dependency on Bootstrap, but with root font-size of 10px.
// These overrides ensure that Bootstrap-dependent UI stays the same size as with 10px root,
// but the actual root font-size in the app can be the default 16px

$black: #000;
$spacer: 0.625rem;
$paragraph-margin-bottom: 0.625rem;

$grid-gutter-width: 0.9375rem;
$border-radius: 0.234375rem;
$border-radius-sm: 0.15625rem;
$border-radius-lg: 0.3125rem;
$border-radius-xl: 0.625rem;
$border-radius-2xl: 1.25rem;
$border-radius-pill: 31.25rem;
$box-shadow: 0 0.3125rem 0.625rem rgba($black, 0.15);
$box-shadow-sm: 0 0.078125rem 0.15625rem rgba($black, 0.075);
$box-shadow-lg: 0 0.625rem 1.875rem rgba($black, 0.175);

$font-size-base: 0.625rem;

$display-font-sizes: (
  1: 3.125rem,
  2: 2.8125rem,
  3: 2.5rem,
  4: 2.1875rem,
  5: 1.875rem,
  6: 1.5625rem,
);

$legend-margin-bottom: 0.3125rem;
$legend-font-size: 0.9375rem;

$list-inline-padding: 0.3125rem;

$table-cell-padding-y: 0.3125rem;
$table-cell-padding-x: 0.3125rem;
$table-cell-padding-y-sm: 0.15625rem;
$table-cell-padding-x-sm: 0.15625rem;

$input-btn-padding-y: 0.234375rem;
$input-btn-padding-x: 0.46875rem;
$input-btn-focus-width: 0.15625rem;
$input-btn-padding-y-sm: 0.15625rem;
$input-btn-padding-x-sm: 0.3125rem;
$input-btn-padding-y-lg: 0.3125rem;
$input-btn-padding-x-lg: 0.625rem;

$form-text-margin-top: 0.15625rem;
$form-label-margin-bottom: 0.3125rem;
$form-color-width: 1.875rem;
$form-check-margin-bottom: 0.078125rem;
$form-check-inline-margin-end: 0.625rem;
$form-range-track-height: 0.3125rem;
$form-range-track-border-radius: 0.625rem;
$form-range-thumb-width: 0.625rem;
$form-range-thumb-border-radius: 0.625rem;
$form-range-thumb-box-shadow: 0 0.0625rem 0.15625rem rgba($black, 0.1);
$form-floating-height: add(2.1875rem, 2px);
$form-floating-padding-y: 0.625rem;
$form-floating-input-padding-t: 1.015625rem;
$form-floating-input-padding-b: 0.390625rem;
$form-floating-label-transform: scale(0.85) translateY(-0.3125rem)
  translateX(0.09375rem);

$nav-link-padding-y: 0.3125rem;
$nav-link-padding-x: 0.625rem;
$navbar-nav-link-padding-x: 0.3125rem;
$navbar-brand-margin-end: 0.625rem;
$navbar-toggler-padding-y: 0.15625rem;
$navbar-toggler-padding-x: 0.46875rem;

$dropdown-min-width: 6.25rem;
$dropdown-padding-y: 0.3125rem;
$dropdown-spacer: 0.078125rem;

$pagination-padding-y: 0.234375rem;
$pagination-padding-x: 0.46875rem;
$pagination-padding-y-sm: 0.15625rem;
$pagination-padding-x-sm: 0.3125rem;
$pagination-padding-y-lg: 0.46875rem;
$pagination-padding-x-lg: 0.9375rem;

$accordion-padding-y: 0.625rem;
$accordion-padding-x: 0.78125rem;
$accordion-icon-width: 0.78125rem;

$tooltip-arrow-width: 0.4rem;
$tooltip-arrow-height: 0.25rem;

$popover-header-padding-y: 0.3125rem;
$popover-arrow-width: 0.625rem;
$popover-arrow-height: 0.3125rem;

$toast-padding-x: 0.46875rem;
$toast-padding-y: 0.3125rem;
$toast-font-size: 0.546875rem;

$modal-footer-margin-between: 0.3125rem;
$modal-dialog-margin: 0.3125rem;
$modal-dialog-margin-y-sm-up: 1.09375rem;

$alert-margin-bottom: 0.625rem;
$progress-height: 0.625rem;
$thumbnail-padding: 0.15625rem;
$breadcrumb-item-padding-x: 0.3125rem;
$breadcrumb-margin-bottom: 0.625rem;

$carousel-caption-padding-y: 0.78125rem;
$carousel-caption-spacer: 0.78125rem;
$carousel-control-icon-width: 1.25rem;

$spinner-width: 1.25rem;
$spinner-width-sm: 0.625rem;

$kbd-padding-y: 0.1171875rem;
$kbd-padding-x: 0.234375rem;
