.leaflet-draw-actions {
  & > li > a {
    background: #dbdbdb;
    color: #000;
  }
}

a[title='Clear all layers'] {
  display: none;
}

.gm-style-cc {
  display: none;
}

.marker-container {
  .prices-bubble {
    min-width: 66px;
    border: 1px solid #fff;
    padding: 5px;
    background-color: $text-color;
    color: $light-bg-color;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    margin-left: -20px;
    margin-bottom: 20px;

    table > tbody > tr {
      line-height: 1;
      > td {
        font-weight: 600;
      }
    }

    img {
      max-width: 33px !important;
      margin: 0 auto 0 auto;
      padding-bottom: 5px;
    }

    &__diesel {
      color: lightgreen;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: $text-color transparent transparent transparent;
    }

    &--booster {
      background-color: $booster-purple;
      &:after {
        border-color: $booster-purple transparent transparent transparent;
      }
    }
    &--ignored {
      opacity: 0.7;
    }
  }

  @keyframes fade-in-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.geosearch {
  form {
    min-height: 30px;

    input {
      min-height: 30px;
    }
  }
}
