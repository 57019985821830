.options-container {
  display: flex;
  height: 100%;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  .h-fit-content {
    height: fit-content;
  }
  .options {
    display: flex;
    align-items: center;
    min-width: fit-content;
    .options-button {
      min-width: 18px;
      min-height: 18px;
      width: 18px;
      height: 18px;
      transition: 0.35s;
      border-radius: 2px;
      position: relative;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        display: none !important;
      }

      &:hover {
        cursor: pointer;
        background-color: $light-box-shadow;
      }
    }
  }
  .three-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      width: 4px;
      height: 4px;
      background-color: #333333;
      border-radius: 50%;
      padding: 0 !important;
      margin-right: 2px;
      :last-child {
        margin-right: 0;
      }
    }
  }
}
