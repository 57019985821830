.btn {
  padding: 10px 12px;
  border-radius: 2px;
  cursor: pointer;

  &.disabled,
  &:disabled {
    opacity: 0.55;
    cursor: default;
  }
}

.btn-primary {
  background-color: $booster-purple;
  border-color: darken($booster-purple, 5%);
  text-transform: uppercase;
  font-size: 12px;

  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus {
    outline: none;
    background-color: darken($booster-purple, 5%);
  }

  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled:focus,
  &.disabled:hover {
    background-color: darken($booster-purple, 5%);
    border-color: darken($booster-purple, 5%);
  }
}

.btn-secondary {
  background-color: $primary-background-color !important;
  border-color: darken($booster-purple, 5%) !important;
  color: $booster-purple !important;
  text-transform: uppercase;
  font-size: 12px;

  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus {
    outline: none;
    background-color: darken($primary-background-color, 5%);
  }

  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled:focus,
  &.disabled:hover {
    background-color: darken($primary-background-color, 5%);
    border-color: darken($primary-background-color, 5%);
  }
}

.btn-outline-primary {
  background-color: transparent;
  border-color: #343a40;
  color: #343a40;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 2px 0 2px;
  margin-left: -5px;
  margin-right: 0px !important;
  font-weight: bolder;

  &:hover,
  &:active,
  &:hover:active,
  &:active:focus {
    outline: transparent;
    background-color: #343a40;
    border-color: #343a40;
    color: #fff;
    box-shadow: none;
  }
  &.dropdown-toggle[aria-expanded='true'] {
    outline: transparent !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
    color: #fff !important;
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    outline: transparent;
    background-color: #343a40;
    border-color: #343a40;
    color: #fff;
    box-shadow: none;
  }

  &[disabled]:focus,
  &[disabled]:hover,
  &.disabled:focus,
  &.disabled:hover {
    background-color: darken(transparent, 5%);
    border-color: darken($booster-purple, 5%);
  }
}

.btn-round {
  border-radius: 50%;
  padding: 0px;
  color: #fff;
  width: 22px;
  height: 22px;
  transition: all 250ms ease-in-out;

  &--default {
    background-color: $dark-background-color;

    &:hover {
      background-color: darken($dark-background-color, 16%);
      color: white;
    }
  }

  &--intercom {
    padding: 0;
    background-color: none;
    width: 22px;
    height: 22px;
    .svg-icon {
      #logo-fill {
        transition: 0.15s !important;
      }
      #circle-fill {
        transition: 0.15s !important;
      }
    }
    &:hover {
      .svg-icon {
        #logo-fill {
          fill: #d3d3d3 !important;
        }
        #circle-fill {
          fill: #176ab2 !important;
        }
      }
    }
    &:hover:disabled {
      .svg-icon {
        #logo-fill {
          fill: #fff !important;
        }
        #circle-fill {
          fill: #573672 !important;
        }
      }
    }

    &:disabled {
      .svg-icon {
        #logo-fill {
          fill: #fff !important;
        }
        #circle-fill {
          fill: #888888 !important;
        }
      }
      opacity: 0.65;
    }
  }
}

.btn-dot {
  display: inline-block;
  background: #939393;
  color: #fff;
  padding: 0px;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  line-height: 18px;
  font-size: 11px;
  text-align: center;

  &:hover {
    background: #606060;
  }

  &:visited,
  &:focus,
  &:active {
    color: #fff;
  }
}

.btn-default {
  &:hover {
    background-color: #ccc;
    border-color: #adadad;
  }
}

.btn-wide {
  width: 100%;
}

.btn-group {
  .btn {
    margin-right: 0 !important;
    outline: none !important;
    padding: 4px 8px;
    border-radius: 3px;
    &:hover:disabled {
      cursor: not-allowed;
    }
    &:focus {
      box-shadow: none;
    }
    &--save {
      &:hover:enabled {
        border-color: $success-color;
        background-color: $success-color;
      }
    }
    &--delete {
      &:hover:enabled {
        border-color: $danger-color;
        background-color: $danger-color;
      }
    }
    &--pause {
      &:hover:enabled {
        border-color: $warning-color;
        background-color: $warning-color;
      }
    }
    &--view-transactions {
      color: $success-color;
      border-color: $success-color;

      &:hover:enabled {
        border-color: $success-color;
        background-color: $success-color;
        color: #fff;
      }
    }
  }
  &--region {
    margin-left: 8px;
  }
}

.btn-upload-file {
  position: relative;

  &.btn.btn-upload-file:not(:disabled):not(.disabled) {
    cursor: default;
  }

  &__input {
    cursor: pointer;
  }

  &__label {
    cursor: pointer;
    display: block;
    text-transform: uppercase;
  }

  &:disabled {
    cursor: default;
  }
}

.card,
.modal {
  .btn {
    font-size: 12px;
    margin-right: 5px;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      text-decoration: none;
    }

    &-default {
      border-color: #ccc;
      background-color: #fff;
      color: $text-color;
    }

    &-region {
      padding: 3px 10px;
    }
  }

  .btn-small {
    padding: 3px 8px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .btn-download {
    &:focus {
      box-shadow: none;
    }
  }

  .btn-default {
    &:hover {
      background-color: #eee;
      border-color: #adadad;
    }
  }
}

.login .card {
  .btn {
    background-color: $booster-purple;
    border-color: darken($booster-purple, 5%);
    color: white;
  }
}

.hypercare-btn {
  border: 0;
  background: none;
  padding: 0;
  margin-right: 4px;
}
