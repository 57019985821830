.btn-group {
  &.btn-group-toggle {
    .btn {
      outline: none;
      box-shadow: none;
    }

    .btn-primary {
      background-color: transparent;
      border-color: #573672;
      color: #333;

      &:hover {
        background-color: rgba(#573672, 0.6);
        color: #fff;
      }

      &.active {
        background-color: #573672;
        border-color: #573672;
        color: #fff;
      }
    }
  }
}

// TODO: button groups should be refactored because of deprecation https://ng-bootstrap.github.io/#/components/buttons/api#NgbRadioGroup
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.radio-btn {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 1px;
  text-align: center;

  & > input[type='radio'] {
    width: auto !important;
  }
}
