@import 'variables';

.modal-backdrop {
  &.lower-z-index {
    z-index: 999 !important;
  }
}

.modal {
  text-align: center;
  // one higher than the side navigation z-index
  // important for small screens
  z-index: 1201;

  &.lower-z-index {
    z-index: 999 !important;
  }

  &.flexible .modal-dialog {
    max-width: 100%;
    width: auto;
  }

  &.modal--medium .modal-dialog {
    width: 440px;
    max-width: none;
  }

  &.delivery-details-modal .modal-dialog {
    width: auto !important;
    max-width: 600px !important;
    min-width: 400px !important;

    .modal-body {
      overflow-y: scroll !important;
    }
  }

  &.reschedule-order-modal .modal-dialog {
    width: auto !important;
    max-width: 500px !important;
    min-width: 400px !important;

    .modal-body {
      overflow-y: visible !important;
    }
    .modal-header {
      border-bottom: none !important;
    }
    .modal-footer {
      border-top: none !important;
    }
  }
  &.add-delivery-note-modal .modal-dialog {
    width: auto !important;
    max-width: 500px !important;
    min-width: 400px !important;

    .modal-body {
      overflow-y: visible !important;
    }
    .modal-header {
      border-bottom: none !important;
    }
    .modal-footer {
      border-top: none !important;
    }

    .ng2-tag-input__text-input {
      padding: 0 !important;
    }
  }

  &.fleet-vehicle-change-fleet-account .modal-dialog,
  &.fleet-vehicle-change-fleet-account .modal-content {
    width: 460px !important;
  }

  p {
    margin-bottom: 5px;
  }

  .modal-content {
    border: 0;
  }

  .modal-header-close {
    background: none;
    border: 0;
    padding: 0 10px;
    font-size: 20px;
    user-select: none;
  }

  .modal-title {
    font-size: 18px;
  }

  .btn-default {
    &:hover {
      background-color: #ccc;
      border-color: #adadad;
    }
  }

  .modal-body {
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0;

      & input {
        text-align: right;
        padding-left: 4px;
        padding-right: 4px;
      }

      select {
        text-align-last: right;

        & option {
          direction: rtl;
        }
      }

      &:first-of-type {
        margin-top: 8px;
      }
    }

    &__row-single-item-right {
      display: flex;
      justify-content: flex-end;
      margin: 16px 0;
    }

    &__addOns_list {
      list-style: none;
      padding: 0 10px;

      li {
        margin-bottom: 7px;
      }

      .switch-input + label {
        float: right;
      }
    }

    &__addOns_messages {
      padding: 0 10px;
    }

    select.form-control {
      height: 31px;
    }

    &__datepicker {
      .toggleDatepicker {
        top: 0;
        right: 0;
      }
    }

    &__row-with-padding {
      padding-top: 5px;
      padding-bottom: 10px;
    }

    &__row-no-margin {
      margin: 0;
    }

    &__row-with-bottom-margin {
      margin-bottom: 12px;

      .datepicker-input-container {
        margin-bottom: 0px;
      }
    }

    &__textarea {
      width: 100%;
      min-height: 16px;
      max-height: 150px;
      padding: 5px;
      border: 1px solid $primary-border-color;
      margin-top: 12px;
    }

    &__textarea-margin-bottom {
      width: 100%;
      min-height: 16px;
      max-height: 150px;
      padding: 5px;
      border: 1px solid $primary-border-color;
      margin-bottom: 12px;
    }

    .accounts-list {
      &__item {
        padding: 2px 0 0 4px;

        & a {
          color: $booster-purple;
        }
      }
    }
  }

  .ngb-timepicker {
    float: right;

    input {
      width: 50px;
    }

    &.ng-invalid input {
      border: 1px solid $danger-color;
    }
  }

  .ngb-dp-weekday,
  .ngb-dp-day {
    font-size: 14px !important;
  }

  .ngb-dp-header {
    min-height: 53px !important;
  }

  .ngb-dp-week {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 370px;

  @include small {
    width: 85%;
    margin: 50px auto;
  }

  @include medium {
    width: 370px;
    margin: 30px auto;
  }

  &.modal-xl {
    max-width: 70%;
    width: 70%;
    max-height: 70%;
    height: 70%;
  }
}

body.modal-open {
  overflow: hidden;
}

.modal-footer {
  justify-content: space-between;

  .btn {
    padding: 6px 12px;
    width: 48%;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    &[disabled] {
      cursor: not-allowed;
    }
  }
}

.authorized-customer {
  .modal-dialog {
    max-width: 330px;
    width: 330px;
  }
}

.confirm-dialog {
  .modal-header + .modal-footer {
    margin-top: -1px;
  }

  &__fuel-type {
    .modal-dialog {
      width: fit-content;
      max-width: 980px !important;
    }

    .btn-small {
      width: 100px;
      text-align: center;
      padding: 0 !important;
      font-size: 11px !important;
      border: 0 !important;
      text-transform: uppercase !important;
    }
  }

  .modal-dialog {
    max-width: 350px;
  }

  .modal-body {
    padding: 13px 15px 10px;

    &__details {
      padding-bottom: 16px;
    }

    .btn {
      padding: 6px 12px;
      width: 48%;
      font-size: 14px;
      font-weight: 700;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
        float: right;
      }
    }
  }
}

.modal-470 {
  .modal-dialog {
    max-width: 470px;
    width: 100%;
  }
}

.add-terminal-fuel-supply-dialog {
  .modal-header + .modal-footer {
    margin-top: -1px;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 100%;
      margin: 30px auto;
    }
  }

  .modal-dialog {
    max-width: 800px;
  }

  .modal-body {
    padding: 13px 15px 10px;

    &__details {
      padding-bottom: 16px;
    }

    .btn {
      padding: 6px 12px;
      width: 48%;
      font-size: 14px;
      font-weight: 700;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
        float: right;
      }
    }
  }
}

.whiteboard-dialog {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-end;
  color: #2b2b2b;
  overflow: hidden;

  .modal-dialog {
    margin: 0 !important;
    padding: 0;
    height: 100%;
    max-height: 100%;
    max-width: 600px;
    min-width: 300px;
    width: 100%;

    .modal-content {
      height: 100%;
      max-height: 100%;
      position: relative;
    }
  }
  .whiteboard-modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-header {
      height: 80px;
      font-size: 20px;
      font-weight: 900;
      display: flex;
      padding: 40px 40px 20px 40px;
      align-items: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      max-height: 100%;
      overflow: hidden;

      &-row {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
        display: flex;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-fixed {
        width: 100%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
      }

      &-scrollable {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 16px 40px;
        flex-grow: 1;
      }
    }

    &-footer {
      height: 80px;
      display: flex;
      padding: 12px 40px;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #dcdcdc;

      &-actions {
        border: 2px solid #6712d7 !important;
        background-color: transparent;
        color: #6712d7;
        font-weight: 700;
        margin-right: 16px;
        font-size: 16px;
        padding: 14px 42px;
        border-radius: 8px;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: #6712d7;
          color: white;
        }

        &.filled {
          background-color: #6712d7;
          color: white;
          &:hover {
            background-color: darken($color: #6712d7, $amount: 10%);
            color: darken($color: white, $amount: 10%);
          }
        }
      }
    }
  }
}

.modal-backdrop {
  // one higher than the side navigation z-index
  // important for small screens
  z-index: 1201 !important;
}

.pc-update-modal {
  .modal-dialog {
    @include medium {
      width: 500px;
      max-width: 500px;
    }
  }
}

.check-vehicle-plate-modal {
  .modal-dialog {
    min-width: 425px !important;
  }
}

.driver-preview-modal,
.service-pro-review-modal {
  .modal-dialog {
    min-width: 375px;
    box-sizing: content-box;
    border: 10px solid #222;
    border-top-width: 32px;
    border-bottom-width: 32px;
    border-radius: 26px;

    .modal-content {
      border-radius: 0;
      background: #f2f2f2;
    }
  }
}

.driver-profile-cropper,
.service-pro-profile-image-cropper {
  .modal-dialog {
    width: 80% !important;
    max-width: 800px;
  }
}

.iframe-modal {
  .modal-dialog {
    max-width: 650px;

    @include medium {
      min-width: 670px;
    }
  }

  iframe {
    width: 100%;
    height: 700px;
  }
}

.confirmation-location-modal {
  .modal-dialog {
    width: 100% !important;
    max-width: 760px;

    .modal-body {
      padding: 0;
    }
  }
}

.add-edit-audience {
  .modal-dialog {
    max-width: 760px;
    max-height: 90vh;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) !important;

    .modal-content {
      .modal-header {
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
        background: #fff;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;

        .btn {
          width: 75px;
        }
      }

      .modal-body {
        max-height: calc(90vh - 68px);
        overflow-y: auto;
        margin-top: 68px;

        &__row input {
          text-align: left !important;
        }

        &__row select {
          text-align-last: left !important;
        }
      }
    }
  }
}

.edit-experiment-variant {
  .modal-dialog {
    width: 100%;
    max-width: 550px;
  }

  .modal-body {
    max-height: 800px;
    overflow-y: auto;
  }

  .loc-string-sets {
    border: 1px solid #f0f0f0;
    background: #f7f7f7;
    padding: 8px;
    border-radius: 5px;
  }
}

.fleet-account-update-failed-dialog {
  .modal-dialog {
    width: auto;
    max-width: 950px;
  }

  .modal-body {
    max-height: 800px;
    overflow-y: auto;
  }
}

.fleet-vehicle-unarchive-modal,
.fleet-vehicle-do-not-fill-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  .empty-list-message {
    padding: 0 20px;
  }

  .card-header-title {
    max-width: 70%;
  }

  .modal-dialog {
    width: fit-content;
    max-width: 980px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .modal-xl {
      width: 900px !important;
      max-width: 900px;
    }
    .modal-content {
      overflow: auto;

      .no-items {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
          font-size: 80px;
          margin-bottom: 12px;
          opacity: 0.4;
        }

        span {
          font-size: 14px;
        }
      }

      .modal-body__row {
        justify-content: stretch;
        align-items: stretch;

        &:first-child {
          padding: 0 16px;
        }
      }

      .modal-header {
        .count {
          font-weight: 800;
        }
      }

      .modal-body__row > .card {
        margin: 12px;
      }

      .card-table tr:last-child {
        border-bottom: 1px solid #d8d8d8;
      }

      archived-fleet-vehicles-list {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        padding: 0 12px;

        > .card {
          height: 400px;
          display: flex;
          justify-content: stretch;
          align-items: stretch;
        }

        tr {
          th:first-child,
          td:first-child {
            vertical-align: middle;
            position: sticky !important;
            background-color: #ececec;
            left: 0;
            padding: 6px;
            display: table-cell;
            z-index: 240;
          }
        }

        thead,
        th {
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 250 !important;
          border-bottom: 0px !important;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
        }

        thead {
          th:first-child {
            z-index: 260 !important;
          }
        }

        .card-block {
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          flex-direction: row;
          -webkit-box-pack: stretch;
          justify-content: stretch;
          -webkit-box-align: stretch;
          align-items: stretch;
          height: 100%;
        }
      }
    }
  }

  .step-2 {
    form {
      padding: 0 16px;
    }

    .card {
      max-width: 800px;
      height: 440px !important;

      .card-block {
        height: 100%;
      }

      tr {
        th:first-child,
        td:first-child {
          vertical-align: middle;
          position: sticky !important;
          background-color: #ececec;
          left: 0;
          padding: 6px;
          display: table-cell;
          z-index: 240;
        }

        &.editing {
          position: sticky;
          top: 34px;
          bottom: 0;
          z-index: 280 !important;
          background-color: #ececec;

          td {
            position: sticky;
            top: 34px;
            bottom: 0;
            z-index: 240 !important;
            background-color: #ececec;
            padding: 14px 12px;
            min-width: 120px;

            input {
              width: 100%;
            }

            &:first-child {
              z-index: 260 !important;
            }
          }
        }
      }

      thead,
      th {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 250 !important;
        border-bottom: 0px !important;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
      }

      thead {
        th:first-child {
          z-index: 260 !important;
        }
      }
    }
  }
}

.promotion-modal {
  > .modal-dialog {
    max-width: 90%;
    width: auto;

    form {
      > div {
        flex: 1;
      }
      .right-column {
        border-left: 1px solid rgb(180, 180, 180);

        .should-propagate {
          margin-top: 15px;
          padding-top: 10px;
          border-top: 1px solid rgb(180, 180, 180);
        }
      }
    }
  }
}

.edit-authorized-customer-roles {
  > .modal-dialog {
    width: auto;
    max-width: none;
  }
}

.user-profile,
.customer-permissions {
  > .modal-dialog {
    width: 90%;
    max-width: 1200px;

    .modal-body {
      padding: 20px;
      padding-bottom: 25px;

      .columns {
        @include large {
          font-size: 13px;
          column-count: 2;
        }
      }

      .initials {
        width: 48px;
        height: 48px;
        background: #6712d7;
        color: #fff;
      }
      a {
        color: #6712d7;
      }
    }
  }
}

.fleet-order-customer-communication-dialog {
  .modal-dialog {
    width: auto;
    max-width: 950px;
  }

  .modal-body {
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .approve-bol .modal-dialog.modal-xl {
    max-width: 95%;
    width: 95%;
    max-height: 95%;
    height: 95%;
  }
}
