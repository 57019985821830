.form-control {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 2px;
  box-shadow: none;
  border-color: $primary-border-color;
  height: 32px;

  &--no-padding {
    padding: 0;
  }

  &:focus {
    border-color: $booster-purple;
    box-shadow: none;
  }

  &[readonly] {
    border-color: $primary-border-color;
  }
}

textarea.form-control {
  height: auto;
}
