.pill {
  border-radius: 15px;
  font-size: 10px;
  font-weight: 900;
  padding: 4px 11px;
  display: inline-block;
  text-align: center;
  color: #fff;

  &--light {
    font-size: 13px;
    font-weight: 400;
    padding: 2px 11px;
  }

  &--success {
    background-color: $success-color;
  }

  &--warning {
    background-color: $warning-color;
  }

  &--danger {
    background-color: $danger-color;
  }

  &--low-premium {
    background-color: #ff5a60;
  }

  &--in_progress-order {
    background-color: #4a90e2;
  }

  &--submitted-order,
  &--complete-order,
  &--initial {
    background-color: #9013fe;
  }

  &--checked_in-order {
    background-color: #99cc2c;
  }

  &--approved-order {
    background-color: #5db2ae;
  }

  &--prices_pending-order {
    background-color: #166c39;
  }

  &--default,
  &--energy-type-info {
    background-color: #f9f9f9;
    color: #000;
    border: 1px solid #d9d9d9;
  }
  &--cancelled-order,
  &--archived-order {
    background-color: #8f8f9a;
    color: #000;
  }

  &--hold-order {
    background-color: #eeb247;
    color: #000;
  }

  &--missed-order {
    background-color: #8e223d;
  }

  &--late-order {
    background-color: #000;
  }

  &--at_risk-order {
    background-color: #000;
  }

  &--invoiced-order {
    background-color: #4a4a4a;
  }

  &--scheduled-order {
    background-color: #ff5a60;
  }

  &--not_needed-order {
    background-color: #4a4a4a;
  }

  &--processing-wex-order {
    background-color: $progress-color;
  }

  &--partial-wex-order,
  &--not_started {
    color: #000;
    background-color: $warning-color;
  }

  &--settled-wex-order {
    background-color: $success-color;
  }

  &--physical-gift-card-order-type {
    background-color: #4a90e2;
  }

  &--digital-gift-card-order-type {
    background-color: #154680;
  }

  &--charged-sale-transaction-status {
    background-color: $success-color;
  }

  &--refunded-sale-transaction-status {
    background-color: $warning-color;
  }

  &--error-sale-transaction-status {
    background-color: $danger-color;
  }

  &--align-right {
    float: right;
  }

  &--m-top-1 {
    margin-top: 1px;
  }

  &--complete {
    background-color: $light-bg-color;
    color: $light-text-color;
  }

  &--success,
  &--active,
  &--final,
  &--activated,
  &--available,
  &--matched,
  &--approved {
    background-color: $success-color;
  }

  &--danger,
  &--cancelled,
  &--notmatched,
  &--rejected,
  &--error {
    background-color: $danger-color;
  }

  &--inprogress,
  &--expired,
  &--scheduled,
  &--submitted {
    background-color: $progress-color;
  }

  &--paused,
  &--on_trip,
  &--requested,
  &--inactive,
  &--mismatched,
  &--inreview,
  &--consumed {
    background-color: $warning-color;
  }

  &--fulfilled,
  &--fueling_complete {
    background-color: $booster-purple;
  }

  &--partial_fill,
  &--expired {
    background-color: $light-purple;
  }

  &--out_of_service {
    background-color: $warn-highlight;
  }

  &--retired {
    background-color: $dark-background-color;
  }

  &--processing-fleet-transaction {
    background-color: $progress-color;
  }

  &--settled-fleet-transaction {
    background-color: $success-color;
  }

  &--error-fleet-transaction {
    background-color: $danger-color;
  }

  &--cancelled-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--resubmitted-fleet-transaction {
    background-color: $warning-color;
  }

  &--written_off-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--direct_invoice_paid-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--to_be_refunded-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--refunding-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--refunded-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--refund_error-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--charged_back-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--direct_invoice_pending-fleet-transaction {
    background-color: $dark-background-color;
  }

  &--vehicle-fleet-issue {
    background-color: $booster-purple;
  }

  &--vehicle_nfc-fleet-issue {
    background-color: $warning-color;
  }

  &--account-fleet-issue {
    background-color: $progress-color;
  }

  &--processing-settlement-status {
    background-color: $progress-color;
  }

  &--error-settlement-status {
    background-color: $danger-color;
  }

  &--fully_settled-settlement-status {
    background-color: $success-color;
  }

  &--partially_settled-settlement-status {
    background-color: $warning-color;
  }

  &--pending-bill-of-lading {
    background-color: $progress-color;
  }

  &--approved-bill-of-lading {
    background-color: $success-color;
  }

  &--rejected-bill-of-lading {
    background-color: $danger-color;
  }

  &--rescheduled {
    background-color: #7418cb;
    color: #fff;
  }
}
