.cronus-marker {
  position: absolute; // do not remove this ever!
  width: 74px;
  height: 39px;
  background: url('/assets/icons/truck-illustration-148x78.png') no-repeat;
  background-size: 74px 39px;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-top: 10px solid #444;

    position: absolute;
    top: 39px;
    left: 29px;
  }

  .label {
    position: absolute;
    left: 37px;
    white-space: nowrap;
    transform: translate(-50%, 0);
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 0px 2px 0px 2px;

    &.tanks {
      top: -10px;
      font-size: 9px;
    }

    &.current-driver {
      top: 9px;
      font-size: 10px;
    }

    &.short-id {
      top: 22px;
      font-size: 10px;
      font-weight: bold;
      line-height: 11px;
    }
  }

  &.selected {
    background: url('/assets/icons/truck-illustration-148x78-selected.png')
      no-repeat;
    background-size: 74px 39px;

    &::before {
      content: '';
      position: absolute;
      top: 33px;
      left: 24px;

      border: 2px solid #222;
      border-radius: 30px;
      height: 25px;
      width: 25px;
      position: absolute;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
    }

    &::after {
      border-top: 10px solid #af8e00;
    }
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.01, 0.01);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

.cronus-fuel-request-marker {
  height: 16px;
  width: 16px;
  border-radius: 32px;

  &.success {
    background: rgba(8, 197, 0, 0.7);
    border: 2px solid #057900;
  }

  &.warning {
    background: rgba(255, 233, 0, 0.7);
    border: 2px solid #778000;
  }

  &.danger {
    background: rgba(255, 41, 0, 0.7);
    border: 2px solid #9a0202;
  }

  &.selected {
    background: rgba(171, 0, 255, 0.7);
    border: 2px solid #6b00a0;
  }
}

.cronus-sidebar-details {
  padding: 20px;
  font-size: 12px;

  &__title {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    .close-btn {
      border: none;
      background-color: transparent;
      color: #e2e2e2;
      transition: color ease 0.3s;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}

.cronus-fr-stats-marker {
  position: absolute; // do not remove this ever!
  background: rgba(255, 255, 255, 0.85);
  width: 130px;
  padding: 4px 8px;
  border: 2px solid #888;
  border-radius: 6px;
  color: $text-color;
  text-shadow: 1px 1px #fff;
  line-height: 16px;
  z-index: 1000 !important;

  .fr-stats {
    color: #028cad;

    &--active {
      color: $success-color;
      font-weight: bold;
    }

    &--morning {
      color: $danger-color;
      font-weight: bold;
    }
  }

  .fr-demand-supply {
    margin-top: 6px;
  }

  .low-supply {
    color: $danger-color;
    font-weight: bold;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #888;
    border-top: 0;
    margin-left: -10px;
    margin-top: -10px;
  }
}
