.horizontal-rule-title {
  padding: 20px 0 25px;
  font-size: 32px;
  overflow: hidden;
  text-align: center;
  color: $light-text-color;

  &--no-top-padding {
    padding-top: 0;
  }

  &:before,
  &:after {
    background-color: $primary-border-color;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
