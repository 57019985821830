.map-select-control {
  position: absolute;
  top: -47px;
  left: 55px;
  z-index: 1000;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;

  &__control-type {
    display: inline-block;
    margin-right: 15px;

    label {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--more-right {
    left: 80px;
  }
}
