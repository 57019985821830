.cdk-listbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  margin-bottom: 0;
  gap: 0.25rem;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  min-width: min-content;

  &.ng-touched.ng-invalid {
    border-color: lightcoral;
  }

  li {
    cursor: pointer;
    background-color: lighten(lightgrey, 10%);
    text-align: end;
    height: 1.25rem;
    border-radius: 0.125rem;
    display: flex;
    padding-inline: 0.75rem;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    font-weight: bold;
    font-size: 10px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: lighten(lightgrey, 9%);
    transition:
      border-color 150ms ease-in-out,
      background-color 150ms ease-in-out,
      padding-inline-start 150ms ease-in-out;

    &:before {
      content: '\2713';
      color: inherit;
      opacity: 0;
      font-size: 0px;
      font-weight: bold;
      transition:
        opacity 150ms ease-in-out,
        font-size 150ms ease-in-out;
    }

    &[aria-selected='true'] {
      border-color: transparent;
      gap: 0.25rem;
      background-color: lighten(lightgrey, 2%);
      padding-inline-start: 0.5rem;

      &:before {
        font-size: 10px;
        opacity: 1;
      }
    }
  }
}
