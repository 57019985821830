$primary-switch-size: 20px;
$primary-switch-position: 1px;
$primary-switch-transition-speed: 0.4s;
$input-background-color: #dddddd;

.switch-label {
  padding: 2px;
  width: $primary-switch-size * 2;
  height: $primary-switch-size;
  background-color: $input-background-color;
  border-radius: $primary-switch-size;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: middle;
  margin-bottom: 0;

  &:after,
  &:before {
    display: block;
    position: absolute;
    top: $primary-switch-position;
    left: $primary-switch-position;
    bottom: $primary-switch-position;
    content: '';
  }

  &:before {
    right: $primary-switch-position;
    background-color: $secondary-background-color;
    border-radius: $primary-switch-size;
    transition: background $primary-switch-transition-speed;
  }

  &:after {
    width: $primary-switch-size;
    background-color: $base-white-color;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin $primary-switch-transition-speed;
  }

  &--checked:before {
    background-color: lighten($balanced, 20%);
  }

  &--checked:after {
    margin-left: $primary-switch-size;
    background-color: $balanced;
  }

  &--disabled {
    cursor: default;
    opacity: 0.4;
  }
}
