.resizing-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  &__left-side {
    flex-basis: 100%;
    padding-bottom: 20px;
    overflow-y: auto;

    @media (min-width: 1100px) {
      flex-basis: 50%;
      padding: 50px 0px 50px 0px;
      border-right: 1px dashed #afafaf;
    }
  }

  aside {
    flex-basis: 100%;

    @media (min-width: 1100px) {
      flex-basis: 50%;
    }
  }

  &__resizable {
    color: #121621;
    margin: auto;

    &:before {
      display: none;
      content: '';
      position: absolute;
      z-index: 1000;
      top: 0;
      bottom: 0;
      width: 8px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.15), transparent);
      @media (min-width: 1100px) {
        display: block;
      }
    }

    @media (max-width: 1099px) {
      display: block;
      margin: 0 30px;
      height: 350px;
    }

    @media (min-width: 1100px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1100px) {
    flex-wrap: nowrap;
  }
}
