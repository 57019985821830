// BREAKPOINT VARIABLES for MQ mixins (in PIXELS!)
$bui-xxs: 375 !default;
$bui-xs: 480 !default;
$bui-sm: 576 !default;
$bui-md: 768 !default;
$bui-lg: 1024 !default;
$bui-xl: 1200 !default;
$bui-xxl: 1440 !default;
$bui-xxxl: 1680 !default;

// FUNCTIONS
@import './helpers/strip-unit';
@import './helpers/rem';
@import './helpers/use-bui-var';

// shadows
$bui-box-shadow: 0 0 b-rem(25px) 1px
  color-mix(in srgb, use-bui-var('bui-black') 20%, transparent);
$bui-panel-shadow:
  0 b-rem(2px) b-rem(4px) -1px
    color-mix(in srgb, use-bui-var('bui-black') 20%, transparent),
  0 b-rem(4px) b-rem(5px) 0
    color-mix(in srgb, use-bui-var('bui-black') 14%, transparent),
  0 1px b-rem(10px) 0
    color-mix(in srgb, use-bui-var('bui-black') 12%, transparent);
$bui-card-shadow: 0px 1px 2px 0px
  color-mix(in srgb, use-bui-var('bui-black') 10%, transparent);

// form fields
$bui-form-field-font-size: use-bui-var('bui-font-size-regular');
$bui-form-field-color: use-bui-var('bui-color-grey-200');
$bui-form-field-background-color: use-bui-var('bui-white');
$bui-form-field-border-color: use-bui-var('bui-color-grey-d00');
$bui-form-field-focused-border-color: use-bui-var('bui-color-brand-600');
$bui-form-field-disabled-opacity: 0.5;
$bui-form-field-disabled-color: use-bui-var('bui-black');

$bui-form-field-disabled-background-color: use-bui-var('bui-color-grey-f00');
$bui-form-field-disabled-border-color: use-bui-var('bui-color-grey-900');
$bui-form-field-error-color: use-bui-var('bui-color-red');

// table
$bui-responsive-table-breakpoint: $bui-md;
$bui-table-cell-padding: b-rem(16px) !default;
$bui-table-row-bg: use-bui-var('bui-color-grey-f50');
$bui-table-row-bg-hover: use-bui-var('bui-color-grey-f00');
$bui-table-border-color: use-bui-var('bui-color-grey-d00');

// lists
$bui-list-breakpoint: $bui-md;

// control-bar
$bui-control-bar-breakpoint: $bui-xs;

// MIXINS
@import './mixins/bui-box-sizing';
@import './mixins/bui-button-reset';
@import './mixins/bui-media-query';
@import './mixins/bui-pseudo-checkmark';
@import './mixins/bui-option-item';
@import './mixins/create-bui-theme';
