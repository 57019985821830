.loader-wrap {
  display: inline;

  &--for-typehead {
    position: absolute;
    top: 5px;
    right: 4px;

    .loader,
    .loader:after {
      width: 20px;
      height: 20px;
    }
  }

  &--panel-form-typehead {
    top: 1px;
  }
}

.loader {
  vertical-align: middle;
  display: inline-block;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid $booster-purple;
  border-right: 5px solid lighten($booster-purple, 20%);
  border-bottom: 5px solid lighten($booster-purple, 20%);
  border-left: 5px solid lighten($booster-purple, 20%);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
